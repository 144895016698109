import classNames from 'classnames/bind';

import styles from './DashboardForAdminPage.module.scss';

const cx = classNames.bind(styles);

const DashboardForAdminPage = () => {
    return <div>DashboardForAdminPage</div>;
};

export default DashboardForAdminPage;
