/* eslint-disable no-undef */
const icons = {
    logo: require('./icons/logo.png'),
    job_market: require('./icons/job_market.png'),
    trend_chart: require('./icons/trend_chart.png'),
    icon_accountant: require('./icons/icon_accountant.png'),
    icon_agrilculture: require('./icons/icon_agrilculture.png'),
    icon_architecture: require('./icons/icon_architecture.png'),
    icon_art: require('./icons/icon_art.png'),
    icon_car_technonogy: require('./icons/icon_car_technonogy.png'),
    icon_construction: require('./icons/icon_construction.png'),
    icon_cosmetics: require('./icons/icon_cosmetics.png'),
    icon_counselor: require('./icons/icon_counselor.png'),
    icon_cuisine: require('./icons/icon_cuisine.png'),
    icon_customer_goods: require('./icons/icon_customer_goods.png'),
    icon_dots: require('./icons/icon_dots.png'),
    icon_EaT: require('./icons/icon_EaT.png'),
    icon_education: require('./icons/icon_education.png'),
    icon_electrical: require('./icons/icon_electrical.png'),
    icon_event_planning: require('./icons/icon_event_planning.png'),
    icon_fashion: require('./icons/icon_fashion.png'),
    icon_finance: require('./icons/icon_finance.png'),
    icon_graphic_design: require('./icons/icon_graphic_design.png'),
    icon_hardware: require('./icons/icon_hardware.png'),
    icon_high_technology: require('./icons/icon_high_technology.png'),
    icon_hotel: require('./icons/icon_hotel.png'),
    icon_hr: require('./icons/icon_hr.png'),
    icon_import_export: require('./icons/icon_import_export.png'),
    icon_interior_design: require('./icons/icon_interior_design.png'),
    icon_interpreter: require('./icons/icon_interpreter.png'),
    icon_investment: require('./icons/icon_investment.png'),
    icon_it: require('./icons/icon_it.png'),
    icon_law: require('./icons/icon_law.png'),
    icon_logistic: require('./icons/icon_logistic.png'),
    icon_manager_operation: require('./icons/icon_manager_operation.png'),
    icon_manufacture: require('./icons/icon_manufacture.png'),
    icon_market: require('./icons/icon_market.png'),
    icon_mechanical: require('./icons/icon_mechanical.png'),
    icon_media: require('./icons/icon_media.png'),
    icon_medical: require('./icons/icon_medical.png'),
    icon_medicine: require('./icons/icon_medicine.png'),
    icon_office: require('./icons/icon_office.png'),
    icon_plant_project: require('./icons/icon_plant_project.png'),
    icon_qaqc: require('./icons/icon_qaqc.png'),
    icon_real_estate: require('./icons/icon_real_estate.png'),
    icon_secretary: require('./icons/icon_secretary.png'),
    icon_service: require('./icons/icon_service.png'),
    icon_sofware: require('./icons/icon_sofware.png'),
    icon_spa: require('./icons/icon_spa.png'),
    icon_stock: require('./icons/icon_stock.png'),
    icon_tourism: require('./icons/icon_tourism.png'),
    icon_transport: require('./icons/icon_transport.png'),
    icon_tv: require('./icons/icon_tv.png'),
    icon_money: require('./icons/icon_money.png'),
    icon_location: require('./icons/icon_location.png'),
    icon_time: require('./icons/icon_time.png'),
    icon_flash: require('./icons/icon_flash.png'),
    icon_bag: require('./icons/icon_bag.png'),
    icon_user: require('./icons/icon_user.png'),
    icon_user_group: require('./icons/icon_user_group.png'),
    icon_award: require('./icons/icon_award.png'),
    icon_upload_cloud: require('./icons/icon_upload_cloud.png'),
    icon_ring: require('./icons/icon_ring.png'),
    icon_logo_text: require('./icons/icon_logo_text.png'),
    icon_point: require('./icons/icon_point.svg'),
    icon_top_point: require('./icons/icon_top_point.png'),
    icon_check_mark: require('./icons/icon_check_mark.png'),
    icon_heart: require('./icons/icon_heart.png'),
    icon_star: require('./icons/icon_star.png'),
    icon_default_logo_company: require('./icons/icon_default_logo_company.png'),
};

const images = {
    background_header: require('./images/bg_header.png'),
    avatar_default: require('./images/avatar_default.jpg'),
    banner: require('./images/banner.jpg'),
    dashboard_item: require('./images/dashboard_item.png'),
    banner1: require('./images/banner1.jpg'),
    banner2: require('./images/banner2.jpg'),
    banner3: require('./images/banner3.jpg'),
    banner4: require('./images/banner4.jpg'),
    bg_header: require('./images/bg_header.png'),
    bg_left_tablet: require('./images/bg_left_tablet.png'),
    bg_right_tablet: require('./images/bg_right_tablet.png'),
    bg_left: require('./images/bg_left.png'),
    bg_right: require('./images/bg_right.png'),
    robot: require('./images/robot.png'),
    banner_r1: require('./images/banner_r1.jpg'),
    banner_r2: require('./images/banner_r2.jpg'),
    banner_r3: require('./images/banner_r3.jpg'),
    banner_r4: require('./images/banner_r4.jpg'),
    hotline_bg: require('./images/hotline_bg.png'),
    hotline: require('./images/hotline.png'),
    tab: require('./images/tab.svg'),
    tab_active: require('./images/tab_active.svg'),
    bg_search_right: require('./images/bg-search-right.png'),
    bg_search_left_opacity: require('./images/bg-search-left-opacity.png'),
    arrow_desktop: require('./images/arrow_desktop.png'),
    cv: require('./images/cv.png'),
    chart: require('./images/chart.png'),
    report_1: require('./images/report_1.png'),
    report_2: require('./images/report_2.png'),
    report_3: require('./images/report_3.png'),
    report_4: require('./images/report_4.png'),
    report_5: require('./images/report_5.png'),
    report_6: require('./images/report_6.png'),
    bussiness: require('./images/bussiness.png'),
    student: require('./images/student.png'),
    banner_manager_login: require('./images/banner_manager_login.png'),
    banner_dash1: require('./images/banner_dash1.png'),
    banner_dash2: require('./images/banner_dash2.png'),
    banner_dash3: require('./images/banner_dash3.png'),
    banner_dash4: require('./images/banner_dash4.png'),
    banner_eKYC: require('./images/banner_eKYC.png'),
    chart_recruitment: require('./images/chart_recruitment.png'),
    member: require('./images/member.png'),
    subtract: require('./images/subtract.svg'),
    invoice_service_empty: require('./images/invoice_service_empty.png'),
    banner_campaign: require('./images/banner-campaign.png'),
    not_found: require('./images/not_found.png'),
    verify_email: require('./images/verify_email.png'),
    empty_02: require('./images/empty_02.png'),
    company_billboard: require('./images/company-billBoard.png'),
    image_search_company: require('./images/img-search-company.png'),
    company_cover_1: require('./images/company_cover_1.jpg'),
};

const gifs = {
    gift_menu: require('./gifs/gift_menu.gif'),
};
export { icons, images, gifs };
