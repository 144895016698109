import {
    HomePage,
    Subpage,
    JobSearchPage,
    LoginPage,
    MatchingJobsPage,
    JobSearchDetailPage,
    ManagerRegisterPage,
    ManagerLoginPage,
    DashboardAdminPage,
    NotFoundPage,
    CompanyDetailPage,
    JobFilterPage,
    AppliedJobPage,
    SavedJobPage,
    ListCompanyPage,
    SearchCompanyPage,
    ChatPage,
} from '@pages';
import route from '@constants/route';
import { ContentOnlyLayout, DashboardHeader, DashboardLayout } from '@layouts';
import {
    DashboardCardPage,
    DashboardSearchCVPage,
    DashboardPostPage,
    DashboardInvoicePage,
    DashboardPostCampaignPage,
    DashboardPostJobPage,
    RecruitmentCampaignPage,
    DashboardSettingPage,
    DashboardVerifyPage,
    DashboardNotFound,
    DashboardForAdminPage,
    DashboardManagerBusinessPage,
    DashboardChatPage,
} from '@pages/DashboardAdminPage';
import { role, groupRole } from '../constants';

export const publicRoutes = [
    {
        path: route.HOMEPAGE,
        component: HomePage,
        isPrivate: false,
        restricted: false,
    },
    {
        path: route.JOB_SEARCH,
        component: JobSearchPage,
        isPrivate: false,
        restricted: false,
    },
    {
        path: route.SUBPAGE,
        component: Subpage,
        isPrivate: false,
        restricted: true,
    },
    {
        path: route.LOGIN,
        component: LoginPage,
        isPrivate: false,
        restricted: true,
        layout: ContentOnlyLayout,
    },
    {
        path: route.REGISTER,
        component: LoginPage,
        isPrivate: false,
        restricted: true,
        layout: ContentOnlyLayout,
    },
    {
        path: route.JOB_FILTER,
        component: JobFilterPage,
        isPrivate: false,
        restricted: false,
    },
    {
        path: route.JOB_SEARCH_DETAIL,
        component: JobSearchDetailPage,
        isPrivate: false,
        restricted: false,
        positionHeader: 'relative',
    },
    {
        path: route.MANAGER_REGISTER,
        component: ManagerRegisterPage,
        isPrivate: false,
        restricted: true,
        layout: ContentOnlyLayout,
    },
    {
        path: route.MANAGER_LOGIN,
        component: ManagerLoginPage,
        isPrivate: false,
        restricted: true,
        layout: ContentOnlyLayout,
    },
    {
        path: route.COMPANY_DETAIL,
        component: CompanyDetailPage,
        isPrivate: false,
        restricted: false,
    },
    {
        path: route.COMPANY,
        component: ListCompanyPage,
        isPrivate: false,
        restricted: false,
    },
    {
        path: route.COMPANY_SEARCH,
        component: SearchCompanyPage,
        isPrivate: false,
        restricted: false,
    },
    {
        path: route.NOT_FOUND,
        component: NotFoundPage,
        isPrivate: false,
        restricted: true,
    },
];

export const privateRoutes = [
    {
        path: route.CHAT,
        component: ChatPage,
        isPrivate: true,
        restricted: false,
    },
    {
        path: route.MATCHING_JOBS,
        component: MatchingJobsPage,
        isPrivate: true,
        restricted: false,
    },
    {
        path: route.APPLIED_JOBS,
        component: AppliedJobPage,
        isPrivate: true,
        restricted: false,
    },
    {
        path: route.SAVED_JOBS,
        component: SavedJobPage,
        isPrivate: true,
        restricted: false,
    },
    {
        path: route.DASHBOARD_ADMIN,
        component: DashboardAdminPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_POST,
        component: DashboardPostPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_SEARCH,
        component: DashboardSearchCVPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_CART,
        component: DashboardCardPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_INVOICE,
        component: DashboardInvoicePage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_RECRUIREMENT_CAMPAIGNS_CREATE,
        component: DashboardPostCampaignPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_RECRUIREMENT_CAMPAIGNS,
        component: RecruitmentCampaignPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_POST_JOB,
        component: DashboardPostJobPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_MANAGER_BUSINESS,
        component: DashboardManagerBusinessPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        roles: groupRole.ADMIN,
    },
    {
        path: route.DASHBOARD_SETTING_PASSWORD,
        component: DashboardSettingPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_SETTING_INFO,
        component: DashboardSettingPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_SETTING_COMPANY,
        component: DashboardSettingPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_SETTING_BUSINESS_LICENSE,
        component: DashboardSettingPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_VERIFFY,
        component: DashboardVerifyPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardHeader,
    },
    {
        path: route.DASHBOARD_FOR_ADMIN,
        component: DashboardForAdminPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
        roles: groupRole.ADMIN,
    },
    {
        path: route.DASHBOARD_NOT_FOUND,
        component: DashboardNotFound,
        isPrivate: true,
        restricted: false,
        layout: DashboardHeader,
        verifyBusinessEmail: true,
    },
    {
        path: route.DASHBOARD_CHAT,
        component: DashboardChatPage,
        isPrivate: true,
        restricted: false,
        layout: DashboardLayout,
    },
];
