import classNames from 'classnames/bind';

import styles from './SettingBusinessLicense.module.scss';

const cx = classNames.bind(styles);

const SettingBusinessLicense = () => {
    return <div className={cx('wrapper')}>SettingBusinessLicense</div>;
};

export default SettingBusinessLicense;
